<template>
    <div class="promo-page">
        <header class="header">
            <div class="logo">
                <img src="../assets/logo.png" alt="Logo" />
            </div>
            <div class="buttons">
                <button @click="businessCooperation(1)">官方交流群</button>
                <button @click="businessCooperation(2)">TG商务合作</button>
            </div>
        </header>
        <main class="content">
            <div class="top-image">
                <img src="../assets/tp01.png" alt="Top Image" />
            </div>
       

            
          
            <div class="carousel">
                <div class="iconContainer"> 
                    <img v-for="imgurl in containerarr" :src="imgurl" class="icon"> 

                </div>
         

            </div>
            <div class="bottom-image">
                <img src="../assets/sm01.png" alt="Bottom Image" />
            </div>
            <div class="download">
                <button @click="down">安卓下载</button>
                <button @click="iosTg">苹果下载</button>
            </div>
            <div style="font-size: 14px; color: rgb(255 171 171);  text-align: left; padding: 15px;"> 
                <samp style="font-weight: bold;"> 温馨提示：</samp> <br/>
                    1、下载文件出错请更换自带浏览器访问。<br/>
                    2、如遇到安装提示恶意程序，请开启飞行模式重新安装。<br/>
                    (本APP绝对不包含病毒，由于行业原因可能存在误报，请大家放心使用。)

            </div>
 
        </main>
        <footer class="footer" >
            <div style="font-size: 14px; text-align: left; padding-left: 15px;">
                <p>永久地址： <span style=" font-weight: bold;" >semi.club</span>  <br/>[收藏回家，如遇打不开请切换网络或挂VPN]</p>
               
            </div>

        </footer>
    </div>
</template>

<script setup>

    import { ref, } from 'vue'
    import router from '@/router/index' 
    const iosTg = () => { 
      var queryString = window.location.search;
      router.push('/ios' + queryString);
    }

    const down = () => { 
      var queryString = window.location.search; 
      window.location.href = "/app"

    }

    const businessCooperation=(num)=>{

      if(num==1){
        window.location.href = "https://t.me/semiqun";
      }else{
        window.location.href = "https://t.me/semi_cs";
      }


    }

 
 
    const containerarr=ref([])
    const iconCount = 56; 
            // 创建图标
    for (let i = 0; i < iconCount; i++) { 
        containerarr.value.push( `/img/${i}.png`)
    } 
</script>

<style>
.promo-page {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    overflow-x: hidden;  
}

.promo-page::before {
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../assets/background.png');
    background-size: cover;
    background-position: top;
    z-index: -2;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.logo img {
    height: 70px;
}

.buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.buttons button {
    margin: 5px 0;
    padding: 10px 15px; 
    width: 120px; 
}

 

.download {
    display: flex;
    justify-content: space-evenly; /* 使用 space-between 使按钮两侧对齐 */
    padding: 1.5vh 0; /* 为上下添加间距 */
}


button{
    background-color: #bb65ad;
    color: white;
    border: none;
    border-radius: 10px;
    
}
 

.download button {
    margin: 0 5px; /* 为按钮之间添加左右间距 */
    margin: 5px 0;
    padding: 15px 20px;
    width: 130px; 
    font-weight: bold;
    font-size: 1rem;
}

.content {
    flex: 1; 
    padding-top: 5vh;
}

.top-image{
    text-align: left;
    padding-left: 20px;

}
 
.top-image img { 
    width: 15rem; 
   
 
}


.bottom-image img {
    width: 20rem; 
    
}

.carousel { 
    width: 600px; /* 容器宽度 */
    margin: 0 auto; /* 居中对齐 */
    padding: 10px 0; /* 内边距 */
    background-color: rgba(0, 0, 0, 0.3); /* 背景颜色 */
    overflow: hidden; /* 隐藏超出部分 */
    display: flex; /* 使子元素使用flex布局 */ 
    margin-top: 1vh;
    margin-bottom: 1vh;

}

.iconContainer {
            display: flex;
            animation: move 15s linear infinite; /* 加快速度 */
            flex-wrap: nowrap; /* 防止换行 */
            width: calc(56 * 70px + 55 * 10px); /* 适应容器宽度 */
        }

.icon {
    width: 70px; /* 设置图标初始宽度 */
    height: auto; /* 自动高度 */
    margin-right: 10px; /* 图标之间的间隔 */
    flex-shrink: 0; /* 防止图标缩小 */
    border-radius: 16px;
}

@keyframes move {
    0% { transform: translateX(0); }
    100% { transform: translateX(-50%); } /* 根据图标数量调整移动距离 */
}

</style>
